<!-- 价格配置组件 -->
<template>
  <table class="table" :class="{ line: showBorder }" border="0" cellpadding="0" cellspacing="0">
    <tr>
      <th v-for="(header, i) in tableHeader" :key="i" :style="{ width: header.width || '' }">
        {{ header.label }}
      </th>
    </tr>
    <tr v-for="(row, rowIndex) in data" :key="rowIndex">
      <td v-for="(header, i) in tableHeader" :key="i" :class="[header.fieldType]">
        <template v-if="header.fieldType === 'label'">
          {{ getLabel(row, header) }}
        </template>
        <template v-if="header.fieldType === 'number'">
          <ykc-form-item :prop="`discountConfig.${rowIndex}.${header.key}`" :rules="header.rules">
            <ykc-input v-model="row[header.key]" type="number" :placeholder="header.placeholder">
              <template slot="append" v-if="header.append">
                {{ header.append }}
              </template>
            </ykc-input>
          </ykc-form-item>
        </template>
      </td>
    </tr>
  </table>
</template>

<script>
  export default {
    name: 'discountConfig',
    props: {
      tableHeader: {
        type: Array,
      },
      showBorder: {
        type: Boolean,
        default: false,
      },
      data: {
        type: Array,
      },
    },
    data() {
      return {};
    },
    methods: {
      getLabel(row, header) {
        if (header.valueField) {
          return header.valueField(row);
        }
        if (header.toFixed) {
          return `${row[header.key]?.toFixed(header.toFixed) ?? '——'}${header.unitName || ''}`;
        }
        return `${row[header.key] ?? '——'}${header.unitName || ''}`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table {
    &.line {
      border-left: 1px solid #eaeaea;
      border-top: 1px solid #eaeaea;
      th,
      td {
        border-right: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
      }
    }
    th {
      height: 36px;
    }
    tr:not(:first-child) {
      &:hover {
        background-color: #ddd !important;
      }
      // &:nth-child(2n) {
      //   background-color: #e3e3e3;
      // }
      // &:nth-child(2n + 1) {
      //   background-color: #efefef;
      // }
      td {
        font-size: 14px;
      }
      td,
      th {
        padding: 5px;
        text-align: center;
        &.label {
          vertical-align: middle;
          height: 30px;
        }
      }
    }
  }
</style>
