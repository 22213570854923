<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-02-14 11:50:50
 * @ Description: 营销管理>标签分组>用户分组>用户分组详情
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail class="box-card">
        <div slot="header" class="clearfix">
          <span>分组信息</span>
        </div>
        <ykc-detail-item label="用户分组编码">
          {{ formData.priceId || '——' }}
        </ykc-detail-item>
        <ykc-detail-item label="用户分组名称">
          {{ formData.contractId || '——' }}
        </ykc-detail-item>
        <ykc-detail-item label="时间范围">
          {{ formData.stationId || '——' }}
        </ykc-detail-item>
        <ykc-detail-item label="分组维度">
          {{ formData.stationId || '——' }}
        </ykc-detail-item>
        <ykc-detail-item label="电站范围">
          {{ formData.stationName || '——' }}
          <span class="status stationStatus" :class="[`status${formData.stationStatusCode}`]">
            {{ getListNameById(dictionary.ENABLE_STATE, formData.stationStatusCode) }}
          </span>
        </ykc-detail-item>
        <ykc-detail-item label="充电次数">
          {{ formData.operatorName || '——' }}
        </ykc-detail-item>
        <ykc-detail-item label="用户数">
          {{ formData.stationOperatorCity || '——' }}
        </ykc-detail-item>
        <ykc-detail-item label="创建人">
          {{ formData.operatorAttribute || '——' }}
        </ykc-detail-item>
        <ykc-detail-item label="创建时间">
          {{ formData.onStationTime || '——' }}
        </ykc-detail-item>
        <ykc-detail-item label="修改人">
          {{ formData.updateTime || '——' }}
        </ykc-detail-item>
        <ykc-detail-item label="修改时间">
          {{ formData.onStationTime || '——' }}
        </ykc-detail-item>
      </ykc-detail>
      <ykc-detail class="box-card">
        <div slot="header" class="clearfix">
          <span>采购详情</span>
        </div>
        <ykc-detail-item label="折扣类型">
          {{ getListNameById(dictionary.DISCOUNT_TYPE, formData.discountType) }}
        </ykc-detail-item>
        <div v-if="formData.discountType === '1'">
          <ykc-detail-item label="电费优惠">
            <span style="width: 80px">
              {{
                getListNameById(
                  dictionary.PREFERENTIAL_TYPE,
                  formData.unifiedDiscount.powerRateType
                )
              }}
            </span>
            <span>
              {{
                formData.unifiedDiscount.powerRate.toFixed(
                  formData.unifiedDiscount.powerRateType === '1' ? 4 : 2
                )
              }}
              {{ formData.unifiedDiscount.powerRateType === '1' ? '元/度' : '%' }}
            </span>
          </ykc-detail-item>
          <ykc-detail-item label="服务费优惠">
            <span style="width: 80px">
              {{
                getListNameById(
                  dictionary.PREFERENTIAL_TYPE,
                  formData.unifiedDiscount.serviceRateType
                )
              }}
            </span>
            <span>
              {{
                formData.unifiedDiscount.serviceRate.toFixed(
                  formData.unifiedDiscount.serviceRateType === '1' ? 4 : 2
                )
              }}
              {{ formData.unifiedDiscount.serviceRateType === '1' ? '元/度' : '%' }}
            </span>
          </ykc-detail-item>
        </div>
        <!-- 分时折扣-->
        <discountConfig
          :tableHeader="tableHeader2"
          :data="discountConfig"
          v-if="formData.discountType === '2'"
          :showBorder="true"
          style="margin-top: 20px"></discountConfig>
        <!-- 原价、活动价展示-->
        <discountConfig
          :tableHeader="tableHeader"
          :data="discountConfig"
          :showBorder="true"
          style="margin-top: 20px"></discountConfig>
      </ykc-detail>
    </div>
  </scroll-layout>
</template>

<script>
  import { purchasePriceStandard } from '@/service/apis';
  import { getListNameById } from '@/utils/index';
  import discountConfig from '@/components/discountConfig.vue';

  export default {
    name: 'PurchasePriceDetail',
    components: {
      discountConfig,
    },
    data() {
      return {
        dictionary: {},
        formData: {
          abc: 1,
        },
        pageButtons: [
          {
            id: '0',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
          {
            id: '1',
            text: '修改密码',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
          {
            id: '2',
            text: '编辑',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    computed: {
      tableHeader2() {
        const type = this.formData.discountTimeType;
        const typeName = type === '2' ? '折扣' : '单价';
        const unitName = type === '2' ? '%' : '元/度';
        const toFixed = type === '2' ? 2 : 4;
        return [
          {
            label: '价格类型',
            fieldType: 'label',
            width: '100px',
            key: 'typeName',
          },
          {
            label: `电费${typeName}`,
            fieldType: 'label',
            width: '150px',
            unitName,
            toFixed,
            key: 'powerRate',
          },
          {
            label: `服务费${typeName}`,
            fieldType: 'label',
            width: '150px',
            unitName,
            toFixed,
            key: 'serviceRate',
          },
        ];
      },
      tableHeader() {
        // const type = this.ruleForm.discountTimeType;
        // const typeName = type === '1' ? '折扣' : '单价';
        return [
          {
            label: '价格类型',
            fieldType: 'label',
            width: '100px',
            key: 'typeName',
          },
          {
            label: `电费原价`,
            width: '150px',
            fieldType: 'label',
            toFixed: 4,
            unitName: '元/度',
            key: 'powerCostPrice',
          },
          {
            label: `服务费原价`,
            width: '150px',
            fieldType: 'label',
            toFixed: 4,
            unitName: '元/度',
            key: 'serviceCostPrice',
          },
          {
            label: `电费采购价`,
            width: '150px',
            fieldType: 'label',
            toFixed: 4,
            unitName: '元/度',
            key: 'powerSpecialPrice',
          },
          {
            label: `服务费采购价`,
            width: '150px',
            fieldType: 'label',
            toFixed: 4,
            unitName: '元/度',
            key: 'serviceSpecialPrice',
          },
        ];
      },
      discountConfig() {
        const list = [];
        const typeList = {
          1: { rowKey: 'jian', name: '尖' },
          2: { rowKey: 'feng', name: '峰' },
          3: { rowKey: 'ping', name: '平' },
          4: { rowKey: 'gu', name: '谷' },
        };
        this.formData.purchasePriceDiscountList?.forEach(o => {
          const obj = typeList[o.priceType];
          if (obj) {
            list.push({
              typeName: obj.name,
              ...o,
            });
          }
        });
        return list;
      },
    },
    created() {
      this.$store
        .dispatch('getDictionaryByKeys', [
          'ENABLE_STATE',
          'DISCOUNT_TYPE',
          'PREFERENTIAL_TYPE',
          'CONFIG_TYPE',
          'STATION_RANGE',
        ])
        .then(res => {
          this.dictionary = res;
        });
      purchasePriceStandard.detail(this.$route.query).then(res => {
        this.formData = res.body;
      });
    },
    methods: {
      getListNameById,
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    .box-card {
      width: 100%;
      height: auto;
      margin: 10px 0px 0px 0px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      .title {
      }
    }
  }
</style>
